import Swiper from 'swiper';
import Handlebars from 'handlebars';

window.addEventListener('load', function () {
  //Get JSON projects Data
  const projectsData = window.projectsData;

  //Create handlebars html templates with projectsData
  const sourceTitle = '{{slideTitle}}';
  const sourceDescription =
    '<h2 class="heading-size-1">{{slideDescription}}</h2>';
  const sourceBtn =
    '<a class="btn" href="{{slideLinkTitle.url}}">{{slideLinkTitle.title}}</a>';

  //Compile handlebars templates
  const templateTitle = Handlebars.compile(sourceTitle);
  const templateDescription = Handlebars.compile(sourceDescription);
  const templatebtn = Handlebars.compile(sourceBtn);

  let oldIndex = null;

  let headerSlider = document.querySelector('.header-slider__carousel');
  let headerImagesSlider = document.querySelector(
    '.page-header__images__container'
  );

  if (headerSlider) {
    // carousel
    headerSlider = new Swiper('.header-slider__carousel', {
      clickable: true,
      loop: true,
      draggable: true,
      init: false,
      a11y: {
        paginationBulletMessage: 'Aller à la diapositive {{index}}',
        nextSlideMessage: 'Prochaine diapositive',
        prevSlideMessage: 'Diapositive précédente',
      },
      pagination: {
        el: '.header-slider__carousel__navigation .swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
      },

      breakpoints: {
        768: {
          pagination: {
            el: '.header-slider__carousel__navigation .swiper-pagination',
            clickable: true,
            type: 'bullets',
            renderBullet: (index, className) => {
              if (index < 9) {
                return `<span class="${className}">0${index + 1}</span>`;
              }
              return `<span class="${className}">${index + 1}</span>`;
            },
          },
        },
      },
    });

    headerSlider.on('init', function () {
      changeTemplateData(headerSlider.realIndex);
    });

    headerSlider.init();

    headerSlider.on('transitionEnd', function () {
      changeTemplateData(headerSlider.realIndex);
    });
  }
  if (headerImagesSlider) {
    // carousel
    headerImagesSlider = new Swiper('.page-header__images__container', {
      clickable: true,
      loop: true,
      draggable: true,
      effect: 'fade',

      a11y: {
        paginationBulletMessage: 'Aller à la diapositive {{index}}',
        nextSlideMessage: 'Prochaine diapositive',
        prevSlideMessage: 'Diapositive précédente',
      },
      autoplay: {
        delay: 6000,
      },
    });
  }

  // Get slide index and get data from projectsData json array
  function changeTemplateData(index) {
    if (oldIndex !== index) {
      console.log(projectsData[index]);
      const htmlTitle = templateTitle(projectsData[index]);
      const htmlDescription = templateDescription(projectsData[index]);
      const htmlBtn = templatebtn(projectsData[index]);
      document.getElementById('slideTitle').innerHTML = htmlTitle;

      if ('' !== projectsData[index].slideLinkTitle) {
        document.getElementById('slideDescription').innerHTML =
          htmlDescription + htmlBtn;
      } else {
        document.getElementById('slideDescription').innerHTML = htmlDescription;
      }
    }
    oldIndex = index;
  }
});
